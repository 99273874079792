import React from 'react'
import { LayoutProvider } from './src/contexts/layout'
import { ParallaxProvider } from 'react-scroll-parallax'

export const wrapRootElement = ({ element }) => {
  return (
    <LayoutProvider>
      <ParallaxProvider>
        {element}
      </ParallaxProvider>
    </LayoutProvider>
  )
}
