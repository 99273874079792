import React, { useReducer, createContext } from 'react'

export const LayoutContext = createContext()

const defaultState = {
  redBg: true
}

const reducer = (state, action) => {
  const { redBg } = state

  switch (action.type) {
    case 'enableRedBg': {
      const updatedObj = !redBg
        ? { redBg: true }
        : {}

      return { ...state, ...updatedObj }
    }

    case 'disableRedBg': {
      const updatedObj = redBg
        ? { redBg: false }
        : {}

      return { ...state, ...updatedObj }
    }

    default: {
      console.log('DataContext missing type', action.type)
      return state
    }
  }
}

export const LayoutProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState)
  const value = { state, dispatch }

  return (
    <LayoutContext.Provider value={value}>
      {children}
    </LayoutContext.Provider>
  )
}